import { template as template_36b8106a18974fa2b815c0a29ca7776b } from "@ember/template-compiler";
const FKControlMenuContainer = template_36b8106a18974fa2b815c0a29ca7776b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
